import React, { useEffect, useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, OutlinedInput, Select, TextField } from "@mui/material";
import TextStyles from './TextStyles.js'
import { ref, set } from "firebase/database";
import { rtdb } from "./firebase.js";
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function Component(props) {
    const [NewCategoryName, setNewCategoryName] = useState('');
    const [NewCategoryIcon, setNewCategoryIcon] = useState(props.Title.toLowerCase().includes('local') ? '\uf54f' : '\uf49d');
    const [DisabledButton, setDisabledButton] = useState(true);
    const [categoriesList, setcategoriesList] = useState([]);
    const [ConfirmationDialogOpen, setConfirmationDialogOpen] = useState(null);
    const unicodeIconsList = ['\uf72d', '\uf725', '\uf826', '\uf551', '\uf824', '\uf823', '\uf821', '\uf4d8', '\uf820', '\uf81f', '\uf81e', '\uf707', '\uf819', '\uf818', '\uf817', '\uf705', '\uf816', '\uf814', '\uf094', '\uf810', '\uf80f', '\uf86b', '\uf805', '\uf79d', '\uf803', '\uf7fe', '\uf578', '\uf7fc', '\uf7fb', '\uf6d7', '\uf6d6', '\uf7f6', '\uf6c7', '\uf563', '\uf73b', '\uf7f1', '\uf7f0', '\uf7ef', '\uf787', '\uf6bd', '\uf786', '\uf7ed', '\uf858', '\uf7ec', '\uf7eb', '\uf5d7', '\uf7e5', '\uf6b1', '\uf5d1', '\uf5ce', '\uf4e3', '\uf72f', '\uf875', '\uf7b7', '\uf7b6', '\uf874', '\uf8c6', '\uf7a1', '\uf7a0', '\uf57b', '\uf000', '\uf869', '\uf79f', '\uf79e', '\uf804', '\uf6e1', '\uf6e0', '\uf0c3', '\uf6c5', '\ue002', '\uf0f4', '\uf561', '\uf858', '\uf517', '\uf0fc', '\uf54f', '\uf49d']

    useEffect(() => {
        setDisabledButton(!NewCategoryName || !NewCategoryIcon);
    }, [NewCategoryName, NewCategoryIcon])

    useEffect(() => {
        setNewCategoryName('');
        setNewCategoryIcon(props.Title.toLowerCase().includes('local') ? '\uf54f' : '\uf49d');
    }, [props.Title])

    useEffect(() => {
        let pre = Object.keys(props.Categories).toSorted() || [];
        let index = pre.indexOf('default');
        if (index)
            pre.splice(index, 1);
        setcategoriesList([...pre, 'default'])
    }, [props.Categories])

    const elemCount = (name) => {
        let count = 0;
        for (let p in props.List)
            if (props.List[p].category === name) count++;
        return count
    }

    return <Box>
        <Box
            sx={{
                ...TextStyles.h2,
                textAlign: 'center',
            }}
        >
            {props.Title}
        </Box>
        <Box
            sx={{
                margin: 'auto',
                width: 'fit-content',
                display: 'flex',
                flexDirection: 'column',
                gap: 1
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 1,
                    alignItems: 'center',
                    userSelect: 'none',
                }}
            >
                <FormControl
                    sx={{
                        '& label:not(.Mui-focused):not(.MuiFormLabel-filled)': { display: 'none' }
                    }}
                >
                    <InputLabel>Icono</InputLabel>
                    <Select
                        lavel='Icono'
                        className='fas'
                        value={NewCategoryIcon}
                        onChange={e => setNewCategoryIcon(e.target.value)}
                        input={<OutlinedInput label="Icono" />}
                        sx={{
                            '&::before': {
                                pl: 2,
                                fontSize: '1.25em',
                                opacity: NewCategoryIcon ? '1' : '0',
                            },
                            '& .MuiSelect-select': {
                                p: 1.5,
                            }
                        }}
                    >
                        {unicodeIconsList.map((icon, i) => {
                            return <MenuItem
                                key={'iconSelector-' + i}
                                className={'fa-fw fas'}
                                value={icon}
                            >
                                {icon}
                            </MenuItem>
                        })}


                    </Select>
                </FormControl>
                <TextField
                    label='Categoría'
                    sx={{
                        flexGrow: 1,
                        '& input': {
                            py: 1.5,
                            px: 2,
                        }
                    }}
                    value={NewCategoryName}
                    onChange={e => setNewCategoryName(e.target.value)}
                />
                <IconButton
                    aria-label="add"
                    color="info"
                    disabled={DisabledButton}
                    onClick={() => {
                        set(ref(rtdb, "public/Categories/" + props.database + '/' + NewCategoryName), NewCategoryIcon)
                        setNewCategoryName('');
                        setNewCategoryIcon(props.Title.toLowerCase().includes('local') ? '\uf54f' : '\uf49d');
                    }}
                >
                    <CheckCircleIcon />
                </IconButton>
            </Box>
            {categoriesList.map((name, i) => {
                let icon = props.Categories[name],
                    count = elemCount(name);
                return <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 1,
                        alignItems: 'center',
                        userSelect: 'none',
                        pl: 0,
                        '& button': {
                            transition: '.3s',
                            opacity: 0,
                        },
                        '&:hover button': {
                            opacity: 1,
                        }
                    }}
                    key={'list-' + props.Title + '-' + name}
                >
                    <Box
                        className='fa-fw fas'
                        sx={{
                            fontSize: 'larger',
                            px: 5,
                        }}
                        onClick={() => {
                            setNewCategoryName(name);
                            setNewCategoryIcon(icon);
                        }}
                    >
                        {icon}
                    </Box>
                    <Box
                        sx={{
                            flexGrow: 1,
                        }}
                        onClick={() => {
                            setNewCategoryName(name);
                            setNewCategoryIcon(icon);
                        }}
                    >
                        {name + ' (' + count + ')'}
                    </Box>
                    <IconButton
                        aria-label="delete"
                        disabled={name === 'default' || !!count}
                        color='error'
                        sx={{
                            visibility: (name === 'default') ? 'hidden' : 'unset',
                        }}
                        onClick={() => setConfirmationDialogOpen(name)}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Box>
            })}
        </Box>
        <Dialog
            onClose={() => setConfirmationDialogOpen(null)}
            open={ConfirmationDialogOpen !== null}
        >
            <DialogTitle id="simple-dialog-title">Confirma la eliminación de la categoría</DialogTitle>
            <DialogContent>
                {props.single + ': ' + ConfirmationDialogOpen}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => setConfirmationDialogOpen(null)}
                    color="primary"
                >
                    Cancelar
                </Button>
                <Button
                    onClick={() => {
                        set(ref(rtdb, "public/Categories/" + props.database + '/' + ConfirmationDialogOpen), null)
                        setConfirmationDialogOpen(null)
                    }}
                    color="primary"
                >
                    Borrar
                </Button>
            </DialogActions>
        </Dialog>
    </Box>
}

export default Component