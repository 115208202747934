import React from "react";
import { Avatar, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";

function Component(props) {
    const findPlace = (place) => {
        const strNormalize = str => {
            str = str.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            return str;
        }
        let nSeacrh = strNormalize(props.Search),
            nPlace = strNormalize(JSON.stringify(place))
        for (let word of nSeacrh.split(' '))
            if (!nPlace.includes(word))
                return false
        return true
    }
    return (
        <List
            sx={{
                overflow: 'auto',
            }}
        >
            {props.listObj.map(place => {
                if (
                    (props.Products && !!props.ActivePlaceId && place.plid === props.ActivePlaceId)
                    || (!props.Products && (!props.Search || findPlace(place)))
                )
                    return (
                        <ListItem
                            sx={{
                                cursor: 'pointer'
                            }}
                            key={'list' + (props.Products ? ('Prods' + place.prid) : place.plid)}
                            onClick={() => props.setActiveId(props.Products ? place.prid : place.plid)}
                        >
                            <ListItemAvatar sx={{ minWidth: 44 }}>
                                {(
                                    (!props.Products && (place.plid === props.ActivePlaceId))
                                    || (props.Products && (place.prid === props.ActiveProductId))
                                ) ? (
                                    <Avatar sx={{ ml: '-10px', my: '-4px' }} className='fa-fw fas'>
                                        {props.categories[place.category || 'default'] || ''}
                                    </Avatar>
                                ) : (
                                    <i className='fa-fw fas'>
                                        {props.categories[place.category || 'default'] || ''}
                                    </i>
                                )}
                            </ListItemAvatar>
                            <ListItemText primary={place.name || 'Sin Nombre'} />
                        </ListItem>
                    )
                else return '';
            })}
        </List>
    )
}

export default Component