import { Box, Button, Paper } from "@mui/material";
import React, { useState } from "react";
import AppMenuEdit from './AppMenuEdit.js';
import CategoriesList from './CategoriesList.js';

function Component(props) {
    const [MenuSelection, setMenuSelection] = useState('Menu');
    return (
        <Paper
            elevation={1}
            sx={{
                p: 2,
                flex: {
                    xs: '100% 0 1',
                    md: '50vw 0 0',
                },
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                maxHeight: '95%',
                overflow: 'auto',
                m: 2,
                mb: 'auto',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                }}
            >
                <Button
                    variant={MenuSelection === 'Menu' ? "contained" : "outlined"}
                    onClick={() => setMenuSelection('Menu')}
                    sx={{
                        fontSize: 'large',
                        borderRadius: '3em',
                        p: 1,
                    }}
                >
                    <big className='fa-fw fas fa-ballot-check' />
                </Button>
                <Button
                    variant={MenuSelection === 'Places' ? "contained" : "outlined"}
                    onClick={() => setMenuSelection('Places')}
                    sx={{
                        fontSize: 'large',
                        borderRadius: '3em',
                        p: 1,
                    }}
                >
                    <big className='fa-fw fas fa-store-alt' />
                </Button>
                <Button
                    variant={MenuSelection === 'Products' ? "contained" : "outlined"}
                    onClick={() => setMenuSelection('Products')}
                    sx={{
                        fontSize: 'large',
                        borderRadius: '3em',
                        p: 1,
                    }}
                >
                    <big className='fa-fw fas fa-box-heart' />
                </Button>
            </Box>
            {MenuSelection === 'Places' ? (
                <CategoriesList
                    Title='Categorías de Locales'
                    Categories={props.PlaceCategories}
                    List={props.Places}
                    database='Places'
                    single='Locales'
                />
            ) : ''}
            {MenuSelection === 'Products' ? (
                <CategoriesList
                    Title='Categorías de Producto'
                    Categories={props.ProductCategories}
                    List={props.Products}
                    database='Products'
                    single='Producto'
                />
            ) : ''}
            {MenuSelection === 'Menu' ? (
                <AppMenuEdit
                    PlaceCategories={props.PlaceCategories}
                    ProductCategories={props.ProductCategories}
                    HomeMenu={props.HomeMenu}
                />
            ) : ''}


        </Paper>
    )
}

export default Component