import React, { useEffect, useState, useRef } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, List, ListItem, ListItemButton, ListItemText, MenuItem, OutlinedInput, Paper, Select, TextField } from "@mui/material";
import TextStyles from './TextStyles.js'
import { ref, set } from "firebase/database";
import { rtdb } from "./firebase.js";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import getUserLocation from './GetUserLocation.js'
import CalcLocationDistance from './CalcLocationDistance.js'
import LocationPick from './LocationPick.js'
import PicturePicker from './PicturePicker.js'
import AddLocationIcon from '@mui/icons-material/AddLocation';
import LocationOffIcon from '@mui/icons-material/LocationOff';
import DeleteIcon from '@mui/icons-material/Delete';
import PlaceIcon from '@mui/icons-material/Place';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import TimeEdit from './TimeEdit.js'
import AddIcon from '@mui/icons-material/Add';
import picStorageUpload from "./picUpload.js";
import picUrl from './picUrl.js';

function Component(props) {
    const [LocationPicking, setLocationPicking] = useState(false);
    const [LocationPickingBase, setLocationPickingBase] = useState(null);
    const [LocationPickingIndex, setLocationPickingIndex] = useState(null);
    const [PlaceName, setPlaceName] = useState(props.ActivePlace.name);
    const [PlaceNameModified, setPlaceNameModified] = useState({});
    const [PlaceCategory, setPlaceCategory] = useState(props.ActivePlace.category);
    const [PlaceCategoryModified, setPlaceCategoryModified] = useState({});
    const [PlaceDesc, setPlaceDesc] = useState(props.ActivePlace.description);
    const [PlaceWorkingHours, setPlaceWorkingHours] = useState(props.ActivePlace.workingHours);
    const [PlacePics, setPlacePics] = useState([]);
    const [PlaceLogo, setPlaceLogo] = useState('');
    const [PlaceLogoModified, setPlaceLogoModified] = useState(false);
    const [PlacePromotedImg, setPlacePromotedImg] = useState('');
    const [PlacePromotedImgModified, setPlacePromotedImgModified] = useState(false);
    const [PlacePromoted, setPlacePromoted] = useState(false);
    const [PlaceDescModified, setPlaceDescModified] = useState({});
    const [PlaceWorkingHoursModified, setPlaceWorkingHoursModified] = useState({});
    const [Locations, setLocations] = useState(JSON.parse(JSON.stringify(props.ActivePlace.locations || [])));
    const [PlaceModified, setPlaceModified] = useState(false);
    const [ConfirmationDialogOpen, setConfirmationDialogOpen] = useState(null);
    const [RmConfirmationDialogOpen, setRmConfirmationDialogOpen] = useState(null);
    const [ConfirmationDialogText, setConfirmationDialogText] = useState(null);
    const [UserLocation, setUserLocation] = useState({ lat: -34.698900, lng: -64.759700, address: '' });
    const [DragOver, setDragOver] = useState(false);


    const loading = useRef(false);

    useEffect(() => {
        loading.current = true;
        setPlaceName(props.ActivePlace.name || '');
        setPlaceCategory(props.ActivePlace.category || '');
        setPlaceDesc(props.ActivePlace.description || '');
        setPlaceWorkingHours(JSON.parse(JSON.stringify(props.ActivePlace.workingHours || [])));
        setLocations(JSON.parse(JSON.stringify(props.ActivePlace.locations || [])));
        setPlacePromoted(props.ActivePlace.promoted || false);
        setPlacePics([...props.ActivePlace.pics || []]);
        setPlaceLogo(props.ActivePlace.logo || '');
        setPlacePromotedImg(props.ActivePlace.promotedImg || '');
    }, [props.ActivePlace.plid, props.ActivePlace])

    useEffect(() => {
        if (PlaceName !== (props.ActivePlace.name || ''))
            setPlaceNameModified({
                focused: true,
                color: "success"
            })
        else
            setPlaceNameModified({})
    }, [PlaceName, props.ActivePlace.name])

    useEffect(() => {
        if (PlaceDesc !== (props.ActivePlace.description || ''))
            setPlaceDescModified({
                focused: true,
                color: "success"
            })
        else
            setPlaceDescModified({})
    }, [PlaceDesc, props.ActivePlace.description])

    useEffect(() => {
        if (JSON.stringify(PlaceWorkingHours) !== JSON.stringify(props.ActivePlace.workingHours || []))
            setPlaceWorkingHoursModified({
                focused: true,
                color: "success",
            })
        else
            setPlaceWorkingHoursModified({})
    }, [PlaceWorkingHours, props.ActivePlace.workingHours])

    useEffect(() => {
        if (PlaceCategory !== (props.ActivePlace.category || ''))
            setPlaceCategoryModified({
                color: "success",
            })
        else
            setPlaceCategoryModified({})
    }, [PlaceCategory, props.ActivePlace.category])

    useEffect(() => {
        setPlaceLogoModified(PlaceLogo !== (props.ActivePlace.logo || ''));
    }, [PlaceLogo, props.ActivePlace.logo])

    useEffect(() => {
        setPlacePromotedImgModified(PlacePromotedImg !== (props.ActivePlace.promotedImg || ''));
    }, [PlacePromotedImg, props.ActivePlace.promotedImg])

    const setLocation = (pos, loc) => {
        let Locs = Locations || [];
        if (loc)
            Locs[pos] = loc;
        else
            Locs.splice(pos, 1);
        setLocations(Locs);
        setLocationPicking(false);
    }

    useEffect(() => {
        let mod = (
            PlaceName !== (props.ActivePlace.name || '')
            || PlaceDesc !== (props.ActivePlace.description || '')
            || PlaceCategory !== (props.ActivePlace.category || '')
            || PlacePromoted !== (props.ActivePlace.promoted || false)
            || PlaceLogo !== (props.ActivePlace.logo || '')
            || PlacePromotedImg !== (props.ActivePlace.promotedImg || '')
            || JSON.stringify(PlaceWorkingHours) !== JSON.stringify(props.ActivePlace.workingHours || [])
            || JSON.stringify(Locations) !== JSON.stringify(props.ActivePlace.locations || [])
            || JSON.stringify(PlacePics) !== JSON.stringify(props.ActivePlace.pics || [])
        )
        if (!mod && loading.current)
            loading.current = false
        setPlaceModified(
            mod && !loading.current
        )
    }, [PlaceName, props.ActivePlace.name, PlaceDesc, props.ActivePlace.description, PlaceCategory, props.ActivePlace.category, PlacePromoted, props.ActivePlace.promoted, PlaceWorkingHours, props.ActivePlace.workingHours, Locations, props.ActivePlace.locations, PlacePics, props.ActivePlace.pics, PlaceLogo, props.ActivePlace.logo, PlacePromotedImg, props.ActivePlace.promotedImg])

    const uploadPlace = () => {
        set(ref(rtdb, "public/Places/" + props.ActivePlace.plid),
            {
                name: PlaceName || '',
                description: PlaceDesc || '',
                category: PlaceCategory || '',
                locations: Locations || [],
                pics: PlacePics || [],
                workingHours: PlaceWorkingHours || [],
                promoted: PlacePromoted || false,
                logo: PlaceLogo || '',
                promotedImg: PlacePromotedImg || '',
            }
        );
    }

    const deleteProduct = () => {
        for (let prid in props.ActivePlace.products)
            set(ref(rtdb, "public/Products/" + prid), null);
        set(ref(rtdb, "public/Places/" + props.ActivePlace.plid), null);
        setRmConfirmationDialogOpen(null);
        props.ClosePlace();
    }

    useEffect(() => {
        getUserLocation().then(loc => {
            if (loc)
                setUserLocation({ ...loc, address: '' });
        });
    }, [])

    const picUpload = (files, fn) => {
        picStorageUpload(files, fn, Object.keys(props.Pictures))
    }

    const PictureDrop = (ev, fn) => {
        ev.preventDefault();
        setDragOver(false);
        if (ev.dataTransfer.items) {
            let pics = [];
            for (let item of [...ev.dataTransfer.items])
                if (item.kind === "file")
                    pics.push(item.getAsFile());
            picUpload(pics, fn);
        } else
            picUpload(ev.dataTransfer.files, fn);
    }

    return (
        <Paper
            elevation={1}
            sx={{
                p: 2,
                flex: {
                    xs: '100% 0 1',
                    md: '50vw 0 0',
                },
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                maxHeight: '95%',
                overflow: 'auto',
                m: 2,
                mb: 'auto'
            }}
            key={'edit' + props.ActivePlace.plid}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 1,
                }}
            >
                <TextField
                    label='Nombre'
                    slotProps={TextStyles.h2}
                    {...PlaceNameModified}
                    disabled={LocationPicking || !props.Admin}
                    sx={{
                        flexGrow: 1
                    }}
                    value={PlaceName || ''}
                    onChange={e => setPlaceName(e.target.value)}
                />
                <IconButton
                    aria-label="promoted"
                    title={(PlacePromoted ? '' : 'No ') + 'Promocionado'}
                    onClick={() => setPlacePromoted(!PlacePromoted)}
                    size="large"
                >
                    {PlacePromoted ? (<StarIcon fontSize="inherit" />) : (<StarBorderIcon fontSize="inherit" />)}
                </IconButton>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 1,
                }}
            >
                <FormControl
                    sx={{
                        flexGrow: 1
                    }}
                >
                    <InputLabel>Categoría</InputLabel>
                    <Select
                        label='Categoría'
                        {...PlaceCategoryModified}
                        disabled={LocationPicking || !props.Admin}
                        value={PlaceCategory || ''}
                        onChange={e => setPlaceCategory(e.target.value)}
                        input={<OutlinedInput label="Categoría" />}
                    >
                        {Object.keys(props.categories).map(category => {
                            if (category.toLowerCase() !== 'default')
                                return (
                                    <MenuItem
                                        key={'catSelector' + category}
                                        value={category}
                                    >
                                        {category}
                                    </MenuItem>
                                )
                            else return '';
                        })}
                    </Select>
                </FormControl>

                <Box
                    component="fieldset"
                    sx={{
                        border: '1px solid ' + (props.DarkMode ? '#ffffff3b' : '#0000003b'),
                        borderRadius: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'relative',
                        width: '3em',
                        height: '4em',
                        marginTop: '-0.5em',
                        borderWidth: 2,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundImage: PlaceLogo ? ('url(' + picUrl(props.Pictures, PlaceLogo) + ')') : 'none',
                        borderColor: PlaceLogoModified ? '#66bb6a' : '',
                        cursor: 'pointer',
                    }}
                    onClick={e => document.getElementById('logoFileInput').click()}
                    onDragOver={ev => {
                        ev.preventDefault();
                        setDragOver(true);
                    }}
                    onDragLeave={ev => {
                        ev.preventDefault();
                        setDragOver(false);
                    }}
                    onDrop={e => PictureDrop(e, setPlaceLogo)}
                >
                    {props.Admin ? (
                        <input
                            id="logoFileInput"
                            type="file"
                            accept="image/*"
                            multiple
                            onChange={e => picUpload(e.target.files, setPlaceLogo)}
                            style={{ display: 'none' }}
                        />
                    ) : ''}
                    <legend
                        style={{
                            fontSize: '.75em',
                            background: props.DarkMode ? '#1e1e1e' : '#fff',
                            px: 2,
                            borderRadius: 8,
                            margin: '0 -20px',
                        }}
                    >Logo</legend>
                    {(props.Admin && !PlaceLogo) ? (
                        <AddIcon
                            sx={{
                                fontSize: 20,
                                opacity: DragOver ? 1 : .5,
                            }}
                        />
                    ) : ''}
                </Box>
            </Box>
            <TimeEdit
                PlaceWorkingHours={PlaceWorkingHours}
                setPlaceWorkingHours={setPlaceWorkingHours}
                Admin={props.Admin}
                DarkMode={props.DarkMode}
                PlaceWorkingHoursModified={PlaceWorkingHoursModified}
            />
            <TextField
                label='Descripción'
                {...PlaceDescModified}
                disabled={LocationPicking || !props.Admin}
                sx={{
                    flexGrow: 1
                }}
                multiline
                rows={4}
                value={PlaceDesc || ''}
                onChange={e => setPlaceDesc(e.target.value)}
            />
            <Accordion
                onChange={(e, expanded) => {
                    if (!expanded)
                        setLocationPicking(false);
                }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    {props.ActivePlace.locations ? (props.ActivePlace.locations.length === 1 ? (props.ActivePlace.locations[0].address.split(',')[0]) : (props.ActivePlace.locations.length + ' direcciones')) : '0 direcciones'}
                </AccordionSummary>
                <AccordionDetails>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <List>
                            {(Locations || []).map((location, i) => {
                                return (
                                    <ListItem
                                        disablePadding
                                        key={'location' + props.ActivePlace.plid + i + JSON.stringify(location)}
                                    >
                                        <ListItemButton>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    gap: 2,
                                                    width: 1,
                                                }}
                                            >
                                                <PlaceIcon />
                                                <ListItemText
                                                    primary={location.address + ' (' + CalcLocationDistance(location, UserLocation) + ')'}
                                                />
                                                {props.Admin ? (
                                                    <IconButton
                                                        edge="end"
                                                        aria-label="Quitar"
                                                        title="Quitar"
                                                        onClick={e => {
                                                            setConfirmationDialogText(location.address || '')
                                                            setConfirmationDialogOpen(i);
                                                        }}
                                                    >
                                                        <LocationOffIcon />
                                                    </IconButton>
                                                ) : ''}
                                                {props.Admin ? (
                                                    <IconButton
                                                        edge="end"
                                                        title="Modificar"
                                                        aria-label="edit"
                                                        onClick={e => {
                                                            setLocationPicking(true);
                                                            setLocationPickingBase(location);
                                                            setLocationPickingIndex(i);
                                                        }}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                ) : ''}
                                            </Box>
                                        </ListItemButton>
                                    </ListItem>
                                )
                            })}
                        </List>
                        {props.Admin ? (
                            <Button
                                variant="outlined"
                                endIcon={<AddLocationIcon />}
                                sx={{
                                    mt: 1,
                                    mx: 'auto',
                                }}
                                onClick={e => {
                                    setLocationPicking(true);
                                    setLocationPickingBase(Locations[0] || UserLocation);
                                    setLocationPickingIndex(Locations.length || 0);
                                }}
                            >
                                Agregar
                            </Button>
                        ) : ''}
                    </Box>
                </AccordionDetails>
            </Accordion>
            <LocationPick
                LocationBase={LocationPickingBase || {}}
                LocationPickingIndex={LocationPickingIndex || 0}
                Show={setLocationPicking}
                setLocation={setLocation}
                Open={LocationPicking}
            />
            <Box
                sx={{
                    display: 'flex',
                    gap: 2,
                }}
            >

                <Box
                    component="fieldset"
                    sx={{
                        border: '1px solid ' + (props.DarkMode ? '#ffffff3b' : '#0000003b'),
                        borderRadius: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'relative',
                        borderWidth: 2,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundImage: PlacePromotedImg ? ('url(' + picUrl(props.Pictures, PlacePromotedImg) + ')') : 'none',
                        borderColor: PlacePromotedImgModified ? '#66bb6a' : '',
                        cursor: 'pointer',
                        width: '10em',
                        flexShrink: 0,
                        minHeight: '260px',
                        '&:hover': {
                            width: PlacePromotedImg ? '100%' : '10em',
                            backgroundSize: 'contain',
                        },
                        '&:hover + fieldset': {
                            display: PlacePromotedImg ? 'none' : '',
                        },
                    }}
                    onClick={e => document.getElementById('PromotedImgFileInput').click()}
                    onDragOver={ev => {
                        ev.preventDefault();
                        setDragOver(true);
                    }}
                    onDragLeave={ev => {
                        ev.preventDefault();
                        setDragOver(false);
                    }}
                    onDrop={e => PictureDrop(e, setPlacePromotedImg)}
                >
                    {props.Admin ? (
                        <input
                            id="PromotedImgFileInput"
                            type="file"
                            accept="image/*"
                            multiple
                            onChange={e => picUpload(e.target.files, setPlacePromotedImg)}
                            style={{ display: 'none' }}
                        />
                    ) : ''}
                    <legend
                        style={{
                            fontSize: '.75em',
                            background: props.DarkMode ? '#1e1e1e' : '#fff',
                            px: 2,
                            borderRadius: 8,
                        }}
                    >Imágen destacada</legend>
                    {(props.Admin && !PlacePromotedImg) ? (
                        <AddIcon
                            sx={{
                                fontSize: 50,
                                opacity: DragOver ? 1 : .5,
                            }}
                        />
                    ) : ''}
                </Box>






                <PicturePicker
                    DarkMode={props.DarkMode}
                    Pictures={props.Pictures}
                    UsedPics={PlacePics}
                    setUsedPics={setPlacePics}
                    Admin={props.Admin}
                />
            </Box>

            {
                (props.Admin && props.ActivePlace.name !== undefined) ? (
                    <Button
                        variant="contained"
                        startIcon={<DeleteIcon />}
                        disabled={false}
                        color="error"
                        onClick={e => setRmConfirmationDialogOpen(true)}
                        sx={{
                            mr: 'auto',
                        }}
                    >
                        Borrar
                    </Button>
                ) : ''
            }
            {
                (PlaceModified && props.Admin) ? (<Button
                    variant="contained"
                    endIcon={<CloudUploadIcon />}
                    disabled={LocationPicking}
                    sx={{
                        ml: 'auto',
                        position: 'sticky',
                        bottom: 0,
                        mt: props.ActivePlace.name !== undefined ? -6.5 : 0,
                    }}
                    onClick={e => uploadPlace()}
                >
                    Grabar
                </Button>) : ''
            }
            <Dialog
                onClose={() => setConfirmationDialogOpen(null)}
                open={ConfirmationDialogOpen !== null}
            >
                <DialogTitle id="simple-dialog-title">Confirma la eliminación de</DialogTitle>
                <DialogContent>
                    {ConfirmationDialogText}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setConfirmationDialogOpen(null)}
                        color="primary"
                    >
                        Cancelar
                    </Button>
                    <Button
                        onClick={() => {
                            setLocation(ConfirmationDialogOpen, undefined);
                            setConfirmationDialogOpen(null)
                        }}
                        color="primary"
                    >
                        Borrar
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                onClose={() => setRmConfirmationDialogOpen(null)}
                open={RmConfirmationDialogOpen !== null}
            >
                <DialogContent sx={{ textAlign: 'center' }}>
                    Confirma la eliminación del local<br />
                    <big>{props.ActivePlace.name || 'Sin Nombre'}</big><br />
                    {Object.keys(props.ActivePlace.products || {}).length ? (
                        <Box>
                            y sus {Object.keys(props.ActivePlace.products || {}).length} productos:
                            <ul
                                style={{
                                    textAlign: 'start',
                                    maxHeight: '30vh',
                                    overflowY: 'auto',
                                }}
                            >
                                {Object.keys(props.ActivePlace.products || {}).map(prid => {
                                    return (
                                        <li
                                            key={'del' + prid}
                                        >
                                            {props.ActivePlace.products[prid].name || 'Sin Nombre'}
                                        </li>
                                    )
                                })}
                            </ul>
                        </Box>
                    ) : ''}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setRmConfirmationDialogOpen(null)}
                    >
                        Cancelar
                    </Button>
                    <Button
                        onClick={() => {
                            deleteProduct();
                            setRmConfirmationDialogOpen(null);
                        }}
                    >
                        Borrar
                    </Button>
                </DialogActions>
            </Dialog>
        </Paper >
    )
}

export default Component