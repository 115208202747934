import React, { useEffect, useState } from "react";
import { Box, Card, CircularProgress, Dialog, IconButton, Pagination } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import picUrl from './picUrl.js';
import picStorageUpload from "./picUpload.js";

function PicButton(props) {
    return (
        <IconButton
            aria-label={props.label}
            size='small'
            sx={{
                position: 'absolute',
                ...props.pos,
                transform: 'scale(0)',
                opacity: 0,
                transition: 'opacity .1s ease-out, background-color .2s ease-out, color .2s ease-out',
                '&:hover': {
                    backgroundColor: 'white',
                    color: props.hoverColor,
                }
            }}
            onClick={props.clickFn}
        >
            <props.Icon />
        </IconButton>
    )
}

function Component(props) {
    const [DragOver, setDragOver] = useState(false);
    const [PicsList, setPicsList] = useState([]);
    const [UploadingPics, setUploadingPics] = useState([]);
    const [PicUploadingIndicator, setPicUploadingIndicator] = useState(false);
    const [PicPickDialogOpen, setPicPickDialogOpen] = useState(false);
    const [UsedPics, setUsedPics] = useState(props.UsedPics || []);
    const [OrderedPics, setOrderedPics] = useState([]);
    const [innerWidth, setInnerWidth] = useState(window.innerWidth);
    const [ShownPics, setShownPics] = useState(24);
    const [PaginationPage, setPaginationPage] = useState(1);

    window.onresize = () => setInnerWidth(window.innerWidth);

    useEffect(() => {
        setUsedPics(props.UsedPics);
    }, [props.UsedPics])

    useEffect(() => {
        setPicsList([...UploadingPics, ...Object.keys(props.Pictures)]);
    }, [props.Pictures, UploadingPics])

    useEffect(() => {
        let pre = [];
        for (let picHash in props.Pictures)
            pre.push({
                ...props.Pictures[picHash],
                order: UsedPics.includes(picHash) ? UsedPics.indexOf(picHash) : (2000000000000 - (props.Pictures[picHash].ts || 0)),
                used: UsedPics.includes(picHash),
                id: picHash,
            });
        pre.sort((a, b) => { return (a.order - b.order) })
        setOrderedPics(pre);
    }, [props.Pictures, UsedPics])

    useEffect(() => {
        setShownPics(innerWidth >= 600 ? 24 : 11)
    }, [innerWidth])

    const PictureDrop = ev => {
        ev.preventDefault();
        setDragOver(false);
        if (ev.dataTransfer.items) {
            let pics = [];
            for (let item of [...ev.dataTransfer.items])
                if (item.kind === "file")
                    pics.push(item.getAsFile());
            picUpload(pics);
        } else
            picUpload(ev.dataTransfer.files);
    }

    const picUpload = (files) => {
        setPicUploadingIndicator(true);
        let hashList = [];
        const addHash = h => {
            hashList.push(h);
            if (hashList.length === files.length) {
                addPic(hashList);
                setPicUploadingIndicator(false);
            }
        };
        picStorageUpload(files, addHash, PicsList, UploadingPics, setUploadingPics)
    }




    const addPic = pics => {
        let pre = [...(UsedPics || [])]
        for (let pic of pics)
            if (!!pic && !UsedPics.includes(pic))
                pre.push(pic);
        if (pre.length !== UsedPics.length)
            props.setUsedPics(pre);
    }

    const rmPic = pic => {
        if (UsedPics.includes(pic)) {
            let pre = [...(UsedPics || [])]
            pre.splice(pre.indexOf(pic), 1);
            props.setUsedPics(pre);
        }
    }

    const relocatePic = (pic, left) => {
        let list = [...(UsedPics || [])],
            preIndex = list.indexOf(pic),
            postIndex = preIndex + (left ? 1 : -1);
        ;
        list[preIndex] = list[postIndex];
        list[postIndex] = pic;
        props.setUsedPics(list);
    }

    return (
        <Box
            component="fieldset"
            sx={{
                border: '1px solid ' + (props.DarkMode ? '#ffffff3b' : '#0000003b'),
                borderRadius: 1,
                display: 'flex',
                justifyContent: 'center',
                position: 'relative',
                flexGrow:1,
            }}

            // open={PicPickDialogOpen}
            // onClose={e => setPicPickDialogOpen(false)}
            onDragOver={ev => {
                ev.preventDefault();
                setDragOver(true);
            }}
            onDragLeave={ev => {
                ev.preventDefault();
                setDragOver(false);
            }}
        >
            {props.Admin ? (
                <input
                    id="picFileInput"
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={e => picUpload(e.target.files)}
                    style={{ display: 'none' }}
                />
            ) : ''}
            <legend
                style={{
                    fontSize: '.75em',
                    opacity: .7,
                }}
            >Fotos</legend>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-evenly',
                    gap: 2,
                    margin: 'auto',
                    overflow: 'hidden auto',
                    maxHeight: 260,
                    p: 5,
                }}
            >
                {props.Admin ? (
                    <Box
                        sx={{
                            display: 'flex',
                            width: 80,
                            height: 80,
                            border: 'solid 1px currentColor',
                            borderRadius: 3,
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                        }}
                        onClick={e => setPicPickDialogOpen(true)}
                    >
                        {PicUploadingIndicator ? (
                            <CircularProgress />
                        ) : (
                            <AddIcon
                                sx={{
                                    fontSize: 40,
                                }}
                            />
                        )}
                    </Box>
                ) : ''}
                {(UsedPics || []).map((picHash, index) => {
                    if (props.Pictures[picHash])
                        return (
                            <Card
                                key={'usedPic' + picHash}
                                raised
                                sx={[
                                    {
                                        width: 80,
                                        height: 80,
                                        borderRadius: 3,
                                        transition: '.1s ease-out',
                                        position: 'relative',
                                    },
                                    {
                                        '&:hover': {
                                            transform: 'scale(2)',
                                            zIndex: 2,
                                            '& button': {
                                                transform: 'scale(0.75)',
                                                opacity: 1,
                                            }
                                        },
                                    },
                                ]}
                            >
                                <Box
                                    component="img"
                                    sx={{
                                        objectFit: 'cover',
                                        width: 1,
                                        height: 1,
                                    }}
                                    alt=''
                                    src={props.Pictures[picHash] ? picUrl(props.Pictures, picHash) : ''}
                                />
                                {props.Admin ? (
                                    <Box>
                                        <PicButton
                                            label="delete"
                                            pos={{
                                                top: -4,
                                                right: -4,
                                                p: 0.5,
                                            }}
                                            hoverColor='red'
                                            clickFn={() => rmPic(picHash)}
                                            Icon={DeleteIcon}
                                        />
                                        {index ? (
                                            <PicButton
                                                label="move prev"
                                                pos={{
                                                    top: '38%',
                                                    left: '-2px',
                                                    p: 0,
                                                }}
                                                hoverColor='#000'
                                                clickFn={() => relocatePic(picHash, false)}
                                                Icon={NavigateBeforeIcon}
                                            />
                                        ) : ''}
                                        {(index < (UsedPics.length - 1)) ? (
                                            <PicButton
                                                label="move next"
                                                pos={{
                                                    top: '38%',
                                                    right: '-2px',
                                                    p: 0,
                                                }}
                                                hoverColor='#000'
                                                clickFn={() => relocatePic(picHash, true)}
                                                Icon={NavigateNextIcon}
                                            />
                                        ) : ''}
                                    </Box>
                                ) : ''}
                            </Card>
                        )
                    else return '';
                })}
            </Box>
            {(DragOver && !PicPickDialogOpen) ? (
                <Box
                    sx={{
                        display: 'flex',
                        border: 'solid 1px currentColor',
                        borderRadius: 3,
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'absolute',
                        top: '8px',
                        bottom: '8px',
                        left: '8px',
                        right: '8px',
                        backgroundColor: props.DarkMode ? '#000b' : '#fffb',
                        backdropFilter: 'saturate(0) blur(1px)',
                    }}
                >
                    <AddIcon
                        sx={{
                            fontSize: 40,
                        }}
                    />
                    <Box
                        onDragOver={ev => {
                            ev.preventDefault();
                            setDragOver(true);
                        }}
                        onDragLeave={ev => {
                            ev.preventDefault();
                            setDragOver(false);
                        }}
                        onDrop={PictureDrop}
                        sx={{
                            position: 'fixed',
                            top: '0',
                            bottom: '0',
                            left: '0',
                            right: '0',
                            zIndex: 10,
                        }}
                    >
                    </Box>
                </Box>
            ) : ''}
            {props.Admin ? (
                <Dialog
                    open={PicPickDialogOpen}
                    onClose={e => setPicPickDialogOpen(false)}
                    onDragOver={ev => {
                        ev.preventDefault();
                        setDragOver(true);
                    }}
                    onClick={ev => {
                        ev.preventDefault();
                        setDragOver(false);
                    }}
                >
                    <Box
                        sx={{
                            minWidth: {
                                xs: '95vmin',
                                md: '50vmin',
                            },
                            position: 'relative',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'grid',
                                gap: 2,
                                p: 5,
                                pb: 2,
                                marginBottom: 'auto',
                                gridTemplateColumns: {
                                    xs: 'repeat(3, 1fr)',
                                    md: 'repeat(5, 1fr)',
                                },
                            }}
                        >

                            <Box
                                sx={{
                                    display: 'flex',
                                    width: 80,
                                    height: 80,
                                    border: 'solid 1px currentColor',
                                    borderRadius: 3,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                }}
                                onClick={e => document.getElementById('picFileInput').click()}
                            >
                                {PicUploadingIndicator ? (
                                    <CircularProgress />
                                ) : (
                                    <AddIcon
                                        sx={{
                                            fontSize: 40,
                                        }}
                                    />
                                )}
                            </Box>
                            {OrderedPics.map((picObj, index) => {
                                if (
                                    index > ((PaginationPage - 1) * ShownPics)
                                    && index <= (PaginationPage * ShownPics)
                                )
                                    return (
                                        <Card
                                            key={'allPics' + (picObj.id || '')}
                                            raised={picObj.used}
                                            sx={[
                                                {
                                                    width: 80,
                                                    height: 80,
                                                    borderRadius: 3,
                                                    transition: '.1s ease-out',
                                                    position: 'relative',
                                                    opacity: picObj.used ? 1 : .5,
                                                    border: picObj.used ? 'solid 2px currentColor' : 'none',
                                                },
                                                {
                                                    '&:hover': {
                                                        transform: 'scale(2)',
                                                        zIndex: 2,
                                                        opacity: 1,
                                                        '& button': {
                                                            transform: 'scale(0.75)',
                                                            opacity: 1,
                                                        }
                                                    },
                                                },
                                            ]}
                                        >
                                            <Box
                                                component="img"
                                                sx={{
                                                    objectFit: 'cover',
                                                    width: 1,
                                                    height: 1,
                                                }}
                                                alt=''
                                                src={picUrl(picObj)}
                                            />
                                            <PicButton
                                                label={picObj.used ? 'delete' : 'add'}
                                                pos={{
                                                    top: -4,
                                                    right: -4,
                                                    p: 0.5,
                                                }}
                                                hoverColor={picObj.used ? 'red' : 'black'}
                                                clickFn={() => { picObj.used ? rmPic(picObj.id) : addPic([picObj.id]) }}
                                                Icon={picObj.used ? DeleteIcon : AddIcon}
                                            />
                                        </Card>
                                    )
                                else return '';
                            })}

                            <Pagination
                                count={Math.ceil(OrderedPics.length / ShownPics)}
                                shape="rounded"
                                page={PaginationPage}
                                onChange={(e, p) => setPaginationPage(p)}
                                sx={{
                                    gridColumn: '1 / -1',
                                    justifyContent: 'center',
                                    display: 'flex',
                                    mx: '-2em',
                                }}
                            />
                        </Box>
                        {DragOver ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    border: 'solid 1px currentColor',
                                    borderRadius: 3,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    position: 'absolute',
                                    top: '8px',
                                    bottom: '8px',
                                    left: '8px',
                                    right: '8px',
                                    backgroundColor: props.DarkMode ? '#000b' : '#fffb',
                                    backdropFilter: 'saturate(0) blur(1px)',
                                }}
                            >
                                <AddIcon
                                    sx={{
                                        fontSize: 40,
                                    }}
                                />
                                <Box
                                    onDragOver={ev => {
                                        ev.preventDefault();
                                        setDragOver(true);
                                    }}
                                    onDragLeave={ev => {
                                        ev.preventDefault();
                                        setDragOver(false);
                                    }}
                                    onDrop={PictureDrop}
                                    sx={{
                                        position: 'fixed',
                                        top: '0',
                                        bottom: '0',
                                        left: '0',
                                        right: '0',
                                        zIndex: 10,
                                    }}
                                >
                                </Box>
                            </Box>
                        ) : ''}
                    </Box>

                </Dialog>
            ) : ''}
        </Box>
    )
}



export default Component