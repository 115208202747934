import React, { useEffect, useState, useRef } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, FormControl, IconButton, InputLabel, MenuItem, OutlinedInput, Paper, Select, TextField } from "@mui/material";
import TextStyles from './TextStyles.js'
import { ref, set } from "firebase/database";
import { rtdb } from "./firebase.js";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PicturePicker from './PicturePicker.js'
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import TimeEdit from './TimeEdit.js'

function Component(props) {
    const [ProductName, setProductName] = useState(props.ActiveProduct.name);
    const [ProductNameModified, setProductNameModified] = useState({});
    const [ProductCategory, setProductCategory] = useState(props.ActiveProduct.category);
    const [ProductCategoryModified, setProductCategoryModified] = useState({});
    const [ProductDesc, setProductDesc] = useState(props.ActiveProduct.description);
    const [ProductDescModified, setProductDescModified] = useState({});
    const [ProductPrice, setProductPrice] = useState(props.ActiveProduct.price);
    const [ProductPriceModified, setProductPriceModified] = useState({});
    const [ProductOfferPrice, setProductOfferPrice] = useState(props.ActiveProduct.offerPrice);
    const [ProductOfferPriceModified, setProductOfferPriceModified] = useState({});
    const [ProductBadge, setProductBadge] = useState(props.ActiveProduct.badge);
    const [ProductPromoted, setProductPromoted] = useState(false);
    const [ProductBadgeModified, setProductBadgeModified] = useState({});
    const [ProductPics, setProductPics] = useState([]);
    const [ProductModified, setProductModified] = useState(false);
    const [RmConfirmationDialogOpen, setRmConfirmationDialogOpen] = useState(null);
    const [ProductAvalabilityHours, setProductAvalabilityHours] = useState(props.ActiveProduct.availabilityHours);
    const [ProductAvalabilityHoursModified, setProductAvalabilityHoursModified] = useState({});

    const loading = useRef(false);

    useEffect(() => {
        loading.current = true;
        setProductName(props.ActiveProduct.name || '');
        setProductCategory(props.ActiveProduct.category || '');
        setProductDesc(props.ActiveProduct.description || '');
        setProductPics([...props.ActiveProduct.pics || []]);
        setProductPrice(props.ActiveProduct.price || '');
        setProductOfferPrice(props.ActiveProduct.offerPrice || '');
        setProductBadge(props.ActiveProduct.badge || '');
        setProductPromoted(props.ActiveProduct.promoted || false);
        setProductAvalabilityHours(JSON.parse(JSON.stringify(props.ActiveProduct.availabilityHours || [])));
    }, [props.ActiveProduct.prid, props.ActiveProduct])

    useEffect(() => {
        if (ProductName !== (props.ActiveProduct.name || ''))
            setProductNameModified({
                focused: true,
                color: "success"
            })
        else
            setProductNameModified({})
    }, [ProductName, props.ActiveProduct.name])

    useEffect(() => {
        if (ProductDesc !== (props.ActiveProduct.description || ''))
            setProductDescModified({
                focused: true,
                color: "success"
            })
        else
            setProductDescModified({})
    }, [ProductDesc, props.ActiveProduct.description])

    useEffect(() => {
        if (ProductCategory !== (props.ActiveProduct.category || ''))
            setProductCategoryModified({
                color: "success"
            })
        else
            setProductCategoryModified({})
    }, [ProductCategory, props.ActiveProduct.category])

    useEffect(() => {
        if (ProductPrice !== (props.ActiveProduct.price || ''))
            setProductPriceModified({
                focused: true,
                color: "success",
            })
        else
            setProductPriceModified({})
    }, [ProductPrice, props.ActiveProduct.price])

    useEffect(() => {
        if (ProductOfferPrice !== (props.ActiveProduct.offerPrice || ''))
            setProductOfferPriceModified({
                focused: true,
                color: "success",
            })
        else
            setProductOfferPriceModified({})
    }, [ProductOfferPrice, props.ActiveProduct.offerPrice])

    useEffect(() => {
        if (ProductBadge !== (props.ActiveProduct.badge || ''))
            setProductBadgeModified({
                focused: true,
                color: "success",
            })
        else
            setProductBadgeModified({})
    }, [ProductBadge, props.ActiveProduct.badge])

    useEffect(() => {
        if (JSON.stringify(ProductAvalabilityHours) !== JSON.stringify(props.ActiveProduct.availabilityHours || []))
            setProductAvalabilityHoursModified({
                focused: true,
                color: "success",
            })
        else
            setProductAvalabilityHoursModified({})
    }, [ProductAvalabilityHours, props.ActiveProduct.availabilityHours])

    useEffect(() => {
        let mod = (
            ProductName !== (props.ActiveProduct.name || '')
            || ProductDesc !== (props.ActiveProduct.description || '')
            || ProductCategory !== (props.ActiveProduct.category || '')
            || ProductPrice !== (props.ActiveProduct.price || '')
            || ProductOfferPrice !== (props.ActiveProduct.offerPrice || '')
            || ProductBadge !== (props.ActiveProduct.badge || '')
            || ProductPromoted !== (props.ActiveProduct.promoted || false)
            || JSON.stringify(ProductAvalabilityHours) !== JSON.stringify(props.ActiveProduct.availabilityHours || [])
            || JSON.stringify(ProductPics) !== JSON.stringify(props.ActiveProduct.pics || [])
        )
        if (!mod && loading.current)
            loading.current = false
        setProductModified(
            mod && !loading.current
        )
    }, [ProductName, props.ActiveProduct.name, ProductDesc, props.ActiveProduct.description, ProductCategory, props.ActiveProduct.category, ProductPrice, props.ActiveProduct.price, ProductOfferPrice, props.ActiveProduct.offerPrice, ProductBadge, props.ActiveProduct.badge, ProductPromoted, props.ActiveProduct.promoted, ProductAvalabilityHours, props.ActiveProduct.availabilityHours, ProductPics, props.ActiveProduct.pics])

    const uploadProduct = () => {
        set(ref(rtdb, "public/Products/" + props.ActiveProduct.prid),
            {
                name: ProductName || '',
                description: ProductDesc || '',
                category: ProductCategory || '',
                price: ProductPrice || '',
                offerPrice: ProductOfferPrice || '',
                badge: ProductBadge || '',
                plid: props.ActiveProduct.plid,
                pics: ProductPics || [],
                availabilityHours: ProductAvalabilityHours || [],
                promoted: ProductPromoted || false,
            }
        );
    }

    const deleteProduct = () => {
        set(ref(rtdb, "public/Products/" + props.ActiveProduct.prid), null);
        props.CloseProduct();
    }

    return (
        <Paper
            elevation={1}
            sx={{
                p: 2,
                flex: {
                    xs: '100% 0 1',
                    md: '50vw 0 0',
                },
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                maxHeight: '95%',
                overflow: 'auto',
                m: 2,
                mb: 'auto',
            }}
            key={'edit' + props.ActiveProduct.prid}
        >
            <Box
                sx={{
                    display: 'flex',
                    gap: 2,
                    alignItems: 'center'
                }}
            >
                <Box
                    sx={{
                        ...TextStyles.h1,
                        flexGrow: 1,
                    }}
                >
                    <small>Local:</small> {props.ActivePlace.name}
                </Box>


                <IconButton
                    aria-label="promoted"
                    title={(ProductPromoted ? '' : 'No ') + 'Promocionado'}
                    onClick={() => setProductPromoted(!ProductPromoted)}
                    size="large"
                >
                    {ProductPromoted ? (<StarIcon fontSize="inherit" />) : (<StarBorderIcon fontSize="inherit" />)}
                </IconButton>


                <IconButton
                    aria-label="close"
                    size="large"
                    onClick={() => props.CloseProduct()}
                >
                    <CancelIcon />
                </IconButton>
            </Box>
            <TextField
                label='Producto'
                slotProps={TextStyles.h2}
                {...ProductNameModified}
                disabled={!props.Admin}
                sx={{
                    flexGrow: 1
                }}
                value={ProductName || ''}
                onChange={e => setProductName(e.target.value)}
            />
            <FormControl>
                <InputLabel>Categoría</InputLabel>
                <Select
                    label='Categoría'
                    {...ProductCategoryModified}
                    disabled={!props.Admin}
                    value={ProductCategory || ''}
                    onChange={e => setProductCategory(e.target.value)}
                    input={<OutlinedInput label="Categoría" />}
                >
                    {Object.keys(props.categories).map(category => {
                        if (category.toLowerCase() !== 'default')
                            return (
                                <MenuItem
                                    key={'catSelector' + category}
                                    value={category}
                                >
                                    {category}
                                </MenuItem>
                            )
                        else return '';
                    })}
                </Select>
            </FormControl>
            <TimeEdit
                PlaceWorkingHours={ProductAvalabilityHours}
                setPlaceWorkingHours={setProductAvalabilityHours}
                PlaceWorkingHoursModified={ProductAvalabilityHoursModified}
                Admin={props.Admin}
                DarkMode={props.DarkMode}
            />
            <TextField
                label='Descripción'
                {...ProductDescModified}
                disabled={!props.Admin}
                sx={{
                    flexGrow: 1
                }}
                multiline
                rows={4}
                value={ProductDesc || ''}
                onChange={e => setProductDesc(e.target.value)}
            />
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    gap: 2
                }}
            >
                <TextField
                    label='Precio de Referencia'
                    {...ProductPriceModified}
                    type="number"
                    disabled={!props.Admin}
                    value={ProductPrice || ''}
                    onChange={e => setProductPrice(e.target.value)}
                    sx={{
                        '& input': {
                            appearance: 'textfield',
                        },
                        '& input::-webkit-inner-spin-button,& input::-webkit-outer-spin-button': {
                            appearance: 'none',
                            display: 'none'
                        }
                    }}
                />
                <TextField
                    label='Precio Oferta'
                    {...ProductOfferPriceModified}
                    type="number"
                    disabled={!props.Admin}
                    value={ProductOfferPrice || ''}
                    onChange={e => setProductOfferPrice(e.target.value)}
                    sx={{
                        '& input': {
                            appearance: 'textfield',
                        },
                        '& input::-webkit-inner-spin-button,& input::-webkit-outer-spin-button': {
                            appearance: 'none',
                            display: 'none'
                        }
                    }}
                />
                <FormControl>
                    <InputLabel>Etiqueta</InputLabel>
                    <Select
                        label='Etiqueta'
                        {...ProductBadgeModified}
                        disabled={!props.Admin}
                        value={ProductBadge || ''}
                        onChange={e => setProductBadge(e.target.value)}
                        input={<OutlinedInput label="Etiqueta" />}
                    >
                        <MenuItem value='15percDisc'>-15%</MenuItem>
                        <MenuItem value='30percDisc'>-30%</MenuItem>
                        <MenuItem value='50percDisc'>-50%</MenuItem>
                        <MenuItem value='2x1'>2 x 1</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <PicturePicker
                DarkMode={props.DarkMode}
                Pictures={props.Pictures}
                UsedPics={ProductPics}
                setUsedPics={setProductPics}
                Admin={props.Admin}
            />
            {(props.Admin && props.ActiveProduct.name !== undefined) ? (
                <Button
                    variant="contained"
                    startIcon={<DeleteIcon />}
                    disabled={false}
                    color="error"
                    onClick={e => setRmConfirmationDialogOpen(true)}
                    sx={{
                        mr: 'auto',
                    }}
                >
                    Borrar
                </Button>
            ) : ''}
            {(ProductModified && props.Admin) ? (<Button
                variant="contained"
                endIcon={<CloudUploadIcon />}
                disabled={false}
                sx={{
                    position: 'sticky',
                    bottom: 0,
                    ml: 'auto',
                    mt: props.ActiveProduct.name !== undefined ? -6.5 : 0,
                }}
                onClick={e => uploadProduct()}
            >
                Grabar
            </Button>) : ''}
            <Dialog
                onClose={() => setRmConfirmationDialogOpen(null)}
                open={RmConfirmationDialogOpen !== null}
            >
                <DialogContent sx={{ textAlign: 'center' }}>
                    Confirma la eliminación del producto<br />
                    <big>{props.ActiveProduct.name || 'Sin Nombre'}</big><br />
                    del local<br /> <big>{props.ActivePlace.name || 'Sin Nombre'}</big>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setRmConfirmationDialogOpen(null)}
                    >
                        Cancelar
                    </Button>
                    <Button
                        onClick={() => {
                            deleteProduct();
                            setRmConfirmationDialogOpen(null);
                        }}
                    >
                        Borrar
                    </Button>
                </DialogActions>
            </Dialog>
        </Paper>
    )
}

export default Component